import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, COMPARISON_OPERATOR, ChangePassword, ConfirmAlternatePassword, PolicyType } from '@app/common';
import { BackupStatisticGrid } from '@app/common/components/delete-user-backup';
import {
  AddAlternateEmail,
  AlternateEmail,
  AutoActivationMode,
  AzureGroup,
  BackupSetting,
  BackupStatistic,
  DeleteBackup,
  OdataBackupSettingSaveRequest,
  OrganizationalUnit,
  Role,
  SelectedPolicies,
  ServiceFields,
  ServiceType,
  ServiceTypeByAPIEnum,
  TasksStateResponse,
  User,
  UserInfoForService,
  UsersPermissions,
  UsersServices,
  hasUserAccountAdminRole
} from '@app/common/models';
import { ODataQuery, ODataService, ODataServiceFactory } from '@app/common/odata';
import { AuthService, OdataObject } from '@app/common/services';
import { OrganizationalUnitsService } from '@app/common/services/organizational-units.service';
import { getErrorText, getServiceUiInfo, hasActionsQueue } from '@app/common/utils';
import { containsWrapper } from '@app/common/utils/functions/search';
import { I18NextPipe } from 'angular-i18next';
import { isArray } from 'lodash';
import { GuidEmpty, naturalSort } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay } from 'rxjs/operators';

export enum UserFiltersEnum {
  all = 'All',
  inDomain = 'InDomain',
  notInDomain = 'NotInDomain',
  suspended = 'Suspended'
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  orderBy: string;
  userProviderState: string;
  usersGroup: string;
  mailFilter: string;
  driveFilter: string;
  contactFilter: string;
  calendarFilter: string;
  inBackup: boolean;
  domainId: string;
  totalSize?: boolean;
  policyType: string;
}

@Injectable({ providedIn: 'root' })
export class UserOdataService {
  #loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  #users$ = new BehaviorSubject<User[]>([]);
  #total$ = new BehaviorSubject<number>(0);

  public requestPending$: Observable<boolean>;

  private readonly allUsersText = this.i18nPipe.transform('common.availableSelect.allUsers', { format: 'title' });

  private readonly state: State;
  private readonly baseUrl: string;
  private readonly myAvailableFilters: { value: string; text: string }[];

  private query: ODataQuery<User>;
  private userService: ODataService<User>;
  private odata: ODataService<any>;

  private oDataFilter: string[] = [];

  constructor(
    private i18nPipe: I18NextPipe,
    private odataFactory: ODataServiceFactory,
    private http: HttpClient,
    private authService: AuthService,
    private orgUnitsService: OrganizationalUnitsService
  ) {
    this.userService = this.odataFactory.CreateService<User>('BackupUsers');
    this.odata = this.odataFactory.CreateService<any>('');
    this.baseUrl = `${this.odata.Query().GetUrl()}/`;

    this.myAvailableFilters = [
      { value: UserFiltersEnum.all, text: this.allUsersText },
      { value: UserFiltersEnum.inDomain, text: this.i18nPipe.transform('common.availableSelect.usersInDomain', { format: 'title' }) },
      { value: UserFiltersEnum.notInDomain, text: this.i18nPipe.transform('common.availableSelect.usersNotInDomain', { format: 'title' }) },
      { value: UserFiltersEnum.suspended, text: this.i18nPipe.transform('common.availableSelect.blockedSuspended', { format: 'title' }) }
    ];
    this.state = {
      page: 1,
      pageSize: 50,
      searchTerm: '',
      orderBy: 'Name',
      userProviderState: this.myAvailableFilters[0].value,
      usersGroup: '',
      mailFilter: null,
      driveFilter: null,
      contactFilter: null,
      calendarFilter: null,
      inBackup: null,
      domainId: null,
      policyType: null
    };

    this.requestPending$ = this.#loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  get users() {
    return this.#users$.asObservable();
  }

  get total() {
    return this.#total$.asObservable();
  }

  get availableFilters() {
    return this.myAvailableFilters;
  }

  get pageSize() {
    return this.state.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  get page() {
    return this.state.page;
  }
  set page(page: number) {
    this._set({ page });
  }

  get userProviderState() {
    return this.state.userProviderState;
  }

  set userProviderState(userProviderState: string) {
    this.state.totalSize = null;
    this._set({ userProviderState });
  }

  set userProviderStateWithTotalSize({ userProviderState, totalSize }) {
    const query = { userProviderState };

    if (totalSize) {
      query['totalSize'] = totalSize;
    }

    this._set(query);
  }

  get usersGroup(): string {
    return this.state.usersGroup;
  }
  set usersGroup(usersGroup: string) {
    this._set({ usersGroup });
  }

  get orderBy() {
    return this.state.orderBy;
  }
  set orderBy(orderBy) {
    this._set({ orderBy });
  }

  set policyType(type) {
    this._set({ policyType: type });
  }
  get policyType() {
    return this.state.policyType;
  }

  get mailFilter() {
    return this.state.mailFilter;
  }
  set mailFilter(mailFilter) {
    this._set({ mailFilter });
  }

  get driveFilter() {
    return this.state.driveFilter;
  }
  set driveFilter(driveFilter) {
    this._set({ driveFilter });
  }

  get contactFilter() {
    return this.state.contactFilter;
  }
  set contactFilter(contactFilter) {
    this._set({ contactFilter });
  }

  get calendarFilter() {
    return this.state.calendarFilter;
  }
  set calendarFilter(calendarFilter) {
    this._set({ calendarFilter });
  }

  get searchTerm() {
    return this.state.searchTerm;
  }
  set searchTerm(searchTerm) {
    this.state.page = 1;
    this.state.searchTerm = searchTerm;
  }

  set domainId(domainId: string) {
    this.state.page = 1;
    this.state.domainId = domainId;
  }

  set inBackup(v: boolean) {
    this.state.page = 1;
    this.state.inBackup = v;
  }

  private _set(patch: Partial<State>) {
    if (!patch.page) {
      this.state.page = 1;
    }

    Object.assign(this.state, patch);
    this.getUsers();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getUsers() {
    this.#loading$.next(true);

    const skip = (this.state.page - 1) * this.state.pageSize;

    this.query = this.userService.Query().Top(this.state.pageSize).Skip(skip).OrderBy(this.state.orderBy);

    this.oDataFilter = [];
    if (this.state.domainId) {
      // this.oDataFilter.push(`(DomainId eq ${this.state.domainId})`);
      // APPS-1039
      // TODO: APPS-1055
    }

    switch (this.state.userProviderState) {
      case UserFiltersEnum.all: {
        if (this.state.inBackup === false && this.state.totalSize) {
          this.oDataFilter.push(`TotalSize ${COMPARISON_OPERATOR.eq} 0`);
        }

        break;
      }
      case UserFiltersEnum.notInDomain: {
        this.oDataFilter.push(`(UserProviderState ${COMPARISON_OPERATOR.eq} '${this.state.userProviderState}')`);

        if (this.state.totalSize) {
          this.oDataFilter.push(`TotalSize ${COMPARISON_OPERATOR.gt} 0`);
        }

        break;
      }
      case UserFiltersEnum.inDomain:
      case UserFiltersEnum.suspended: {
        this.oDataFilter.push(`(UserProviderState ${COMPARISON_OPERATOR.eq} '${this.state.userProviderState}')`);
      }
    }

    if (this.state.searchTerm) {
      this.oDataFilter.push(
        `(${containsWrapper('Name', this.state.searchTerm)} ${BOOLEAN_OPERATOR.or} ${containsWrapper('Email', this.state.searchTerm)})`
      );
    }

    if (this.state.mailFilter !== null) {
      if (this.state.mailFilter) {
        this.oDataFilter.push(`MailEnable ${COMPARISON_OPERATOR.eq} ${this.state.mailFilter}`);
      } else {
        this.oDataFilter.push(
          `MailEnable ${COMPARISON_OPERATOR.eq} ${this.state.mailFilter} ${BOOLEAN_OPERATOR.or} UserProviderState ${COMPARISON_OPERATOR.eq} '${UserFiltersEnum.notInDomain}' ${BOOLEAN_OPERATOR.or} InBackup ${COMPARISON_OPERATOR.eq} false`
        );
      }
    }

    if (this.state.driveFilter !== null) {
      if (this.state.driveFilter) {
        this.oDataFilter.push(`DriveEnable ${COMPARISON_OPERATOR.eq} ${this.state.driveFilter}`);
      } else {
        this.oDataFilter.push(
          `DriveEnable ${COMPARISON_OPERATOR.eq} ${this.state.driveFilter} ${BOOLEAN_OPERATOR.or} UserProviderState ${COMPARISON_OPERATOR.eq} '${UserFiltersEnum.notInDomain}' ${BOOLEAN_OPERATOR.or} InBackup ${COMPARISON_OPERATOR.eq} false`
        );
      }
    }

    if (this.state.contactFilter !== null) {
      if (this.state.contactFilter) {
        this.oDataFilter.push(`ContactsEnable ${COMPARISON_OPERATOR.eq} ${this.state.contactFilter}`);
      } else {
        this.oDataFilter.push(
          `ContactsEnable ${COMPARISON_OPERATOR.eq} ${this.state.contactFilter} ${BOOLEAN_OPERATOR.or} UserProviderState ${COMPARISON_OPERATOR.eq} '${UserFiltersEnum.notInDomain}' ${BOOLEAN_OPERATOR.or} InBackup ${COMPARISON_OPERATOR.eq} false`
        );
      }
    }

    if (this.state.calendarFilter !== null) {
      if (this.state.calendarFilter) {
        this.oDataFilter.push(`CalendarEnable ${COMPARISON_OPERATOR.eq} ${this.state.calendarFilter}`);
      } else {
        this.oDataFilter.push(
          `CalendarEnable ${COMPARISON_OPERATOR.eq} ${this.state.calendarFilter} ${BOOLEAN_OPERATOR.or} UserProviderState ${COMPARISON_OPERATOR.eq} '${UserFiltersEnum.notInDomain}' ${BOOLEAN_OPERATOR.or} InBackup ${COMPARISON_OPERATOR.eq} false`
        );
      }
    }

    this.updatePolicyTypeFilter();

    const anyFilters = this.state.mailFilter || this.state.driveFilter || this.state.contactFilter || this.state.calendarFilter;

    if (anyFilters) {
      this.oDataFilter.push(`UserProviderState ${COMPARISON_OPERATOR.ne} '${this.myAvailableFilters[2].value}'`);
    }

    if (anyFilters) {
      this.oDataFilter.push(`InBackup ${COMPARISON_OPERATOR.eq} ${this.state.inBackup === false ? String(this.state.inBackup) : 'true'}`);
    } else if (this.state.inBackup !== null) {
      this.oDataFilter.push(`InBackup ${COMPARISON_OPERATOR.eq} ${String(this.state.inBackup)}`);
    }

    if (this.oDataFilter.length > 0) {
      this.query = this.query.Filter(this.oDataFilter.join(` ${BOOLEAN_OPERATOR.and} `));
    }

    if (this.state.usersGroup) {
      this.query = this.query.CustomQueryOptions({ key: 'folderId', value: this.state.usersGroup });
    }

    this.query
      .ExecWithCount()
      .pipe(finalize(() => this.#loading$.next(false)))
      .subscribe({
        next: (res) => {
          this.#total$.next(res.count);
          this.#users$.next(res.data);
          this.#loading$.next(false);
        }
      });
  }

  private updatePolicyTypeFilter(): void {
    const getFiltersFn = (property, operator, separator) => {
      const filters = ['MailPolicyType', 'DrivePolicyType', 'ContactPolicyType', 'CalendarPolicyType'];

      if (this.authService.isOffice) {
        filters.push('SharePointPolicyType');
      }

      if (this.authService.isGoogle) {
        filters.push('TeamDrivePolicyType');
      }

      return filters.map((prop: string) => `${prop} ${operator} '${property}'`).join(` ${separator} `);
    };

    switch (this.state.policyType) {
      case 'All':
        break;
      case PolicyType.none:
        this.oDataFilter.push(getFiltersFn(PolicyType.none, `${COMPARISON_OPERATOR.eq}`, `${BOOLEAN_OPERATOR.and}`));
        break;
      case PolicyType.default:
        this.oDataFilter.push(
          '(' +
            getFiltersFn(PolicyType.default, `${COMPARISON_OPERATOR.eq}`, `${BOOLEAN_OPERATOR.and}`) +
            ')' +
            ` ${BOOLEAN_OPERATOR.or} ` +
            '((' +
            getFiltersFn(PolicyType.default, `${COMPARISON_OPERATOR.eq}`, `${BOOLEAN_OPERATOR.or}`) +
            `) ${BOOLEAN_OPERATOR.and} ` +
            '(' +
            getFiltersFn(PolicyType.custom, `${COMPARISON_OPERATOR.ne}`, `${BOOLEAN_OPERATOR.and}`) +
            '))'
        );
        break;
      case PolicyType.custom:
        this.oDataFilter.push(getFiltersFn(PolicyType.custom, `${COMPARISON_OPERATOR.eq}`, `${BOOLEAN_OPERATOR.or}`));
        break;
    }
  }

  /*
   *
   * @param serviceType
   * @param search - contains in user email
   * @param includeUsersId - array of user isd that return object should contain
   */
  getUserWithServiceInfo(serviceType: ServiceType, search: string = null, includeUsersId: string[] = []): Observable<UserInfoForService[]> {
    this.#loading$.next(true);
    const fields = new ServiceFields(serviceType);
    const selectedFields = ['Id', 'Email', 'Name', ...fields.filteredArrayValues()];
    const filter: string[] = [];

    if (search) {
      filter.push(`(${containsWrapper('Email', search)} ${BOOLEAN_OPERATOR.or} ${containsWrapper('Name', search)})`);
    }

    const query = this.userService.Query().Top(500).Select(selectedFields);
    const req: { users?: Observable<User[]>; selectedUsers?: Observable<User[]> } = {
      users: query.Filter(filter.join(` ${BOOLEAN_OPERATOR.and} `)).Exec()
    };

    if (includeUsersId.length > 0) {
      req.selectedUsers = query.Filter(`Id in (${includeUsersId.join(', ')})`).Exec();
    }

    return forkJoin(req).pipe(
      finalize(() => this.#loading$.next(false)),
      map((req: { users: User[]; selectedUsers: User[] }) => {
        const users = req.users;
        const selected = req.selectedUsers;

        if (selected && !search) {
          selected.forEach((user) => {
            if (!users.some((u) => u.Id == user.Id)) {
              users.unshift(user);
            }
          });
        }

        return users;
      }),
      map((users) => {
        return users.map((u) => {
          return {
            Id: u.Id,
            Name: u.Name,
            Email: u.Email,
            LastBackupDate: u[fields.LastBackupDate] as string,
            TotalSize: (fields.Size ? u[fields.Size] : 0) as number
          };
        });
      })
    );
  }

  deleteUser(ids: string | string[]): Observable<any> {
    const request$ = isArray(ids)
      ? this.userService.CustomCollectionAction('DeleteSelected', { Ids: ids })
      : this.userService.CustomAction(ids, 'Delete', null);

    this.#loading$.next(true);
    return request$.pipe(finalize(() => this.#loading$.next(false)));
  }

  updatePermissions(user: User, userIds?: string[]): Observable<any> {
    const options = {
      Ids: userIds ? userIds : [user.Id],
      SignInEnable: user.SignInEnable,
      RestoreEnable: user.RestoreEnable
    };

    return this.odata.CustomCollectionAction('SetUsersPermissions', options);
  }

  getUsersPermissions(userIds: string[]): Observable<UsersPermissions> {
    return this.http.get<UsersPermissions>(`${this.baseUrl}GetUsersPermissions?userIds=${userIds.join(',')}`);
  }

  getUsersServices(userIds: string[]): Observable<UsersServices> {
    return this.http.get<UsersServices>(`${this.baseUrl}GetUsersServices?userIds=${userIds.join(',')}`);
  }

  configureServices(userIds: string[], backupSettings: BackupSetting[]): Observable<any> {
    const postData: OdataBackupSettingSaveRequest = {
      Ids: userIds,
      Services: backupSettings.map((s) => {
        return { Service: ServiceType[s.serviceType], Enabled: s.enabled };
      })
    };

    return this.odata.CustomCollectionAction('ConfigureServices', postData);
  }

  updateAttachedPolicies(userIds: string[], policy: SelectedPolicies): Observable<any> {
    const options = {
      UserIds: userIds,
      EmailPolicyId: this.convertPolicyId(policy.emailPolicyId),
      DrivePolicyId: this.convertPolicyId(policy.drivePolicyId),
      ContactPolicyId: this.convertPolicyId(policy.contactPolicyId),
      CalendarPolicyId: this.convertPolicyId(policy.calendarPolicyId),
      SitePolicyId: this.convertPolicyId(policy.sitePolicyId),
      TeamDrivePolicyId: this.convertPolicyId(policy.teamDriveId),
      TeamsPolicyId: this.convertPolicyId(policy.teamsPolicyId)
    };

    return this.odata.CustomCollectionAction('SetAttachedPolicies', options);
  }

  /* Need for get normalize value from method getEmptyAttachedPolicy() */
  private convertPolicyId = (id: string): string => id || null;

  deleteBackup(deleteModel: DeleteBackup): Observable<any> {
    this.#loading$.next(true);
    return this.odata.CustomCollectionAction('DeleteBackup', deleteModel).pipe(
      catchError((err: HttpErrorResponse) => throwError(() => getErrorText(err))),
      finalize(() => this.#loading$.next(false))
    );
  }

  getBackupStatistic(userIds: string[], include: boolean): Observable<BackupStatistic[]> {
    return this.http
      .get(`${this.baseUrl}GetBackupStatistic?userIds=${userIds.join(',')}&includeTeamDriveSharePoint=${include}`)
      .pipe(map((res: OdataObject<BackupStatistic[]>) => res.value));
  }

  getTasksState(userId: string): Observable<TasksStateResponse> {
    return this.userService.CustomFunction(userId, 'GetTasksState').pipe(map((res: HttpResponse<TasksStateResponse>) => res.body));
  }

  getAlternateEmail(userId: string): Observable<AlternateEmail> {
    return this.userService.CustomFunction(userId, 'GetAlternateEmail').pipe(map((res: HttpResponse<AlternateEmail>) => res.body));
  }

  addAlternateEmail(model: AddAlternateEmail): Observable<any> {
    const options = {
      Email: model.email,
      Password: model.password,
      ConfirmPassword: model.confirmPassword
    };

    return this.userService.CustomAction(model.userId, 'AddAlternateEmail', options).pipe(
      map((res: HttpResponse<{ body: any }>) => res.body),
      catchError((err: HttpErrorResponse) => {
        const _error = err && (err.error as { value: string }).value;
        const errorMessage = _error ? _error : 'Error adding alternate Email';

        return throwError(() => errorMessage);
      })
    );
  }

  editAlternateEmail(userId: string, email: string, password: string): Observable<any> {
    const options = {
      Email: email,
      Password: password
    };

    return this.userService.CustomAction(userId, 'EditAlternateEmail', options).pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }

  editAlternatePassword(userId: string, password: string, newPassword: string, confirmPassword: string) {
    const options = {
      Password: password,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword
    } as ChangePassword;

    return this.userService
      .CustomAction(userId, 'EditAlternatePassword', options)
      .pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }

  deleteAlternateEmail(userId: string, password?: string) {
    return this.userService
      .CustomAction(userId, 'DeleteAlternateEmail', password ? { Password: password } : {})
      .pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }

  getSecretForAlternate(userId: string): Observable<string> {
    return this.userService.CustomFunction(userId, 'GetTwoStepSecret').pipe(map((res: HttpResponse<{ value: string }>) => res.body.value));
  }

  enableAlternateTwoStep(userId: string, password: string, responseCode: string) {
    const options = {
      Password: password,
      ResponseCode: responseCode
    };

    return this.userService
      .CustomAction(userId, 'EnableAlternateTwoStep', options)
      .pipe(map((res: HttpResponse<{ bode: any }>) => res.body));
  }

  getUserGroups(): Observable<AzureGroup[]> {
    this.#loading$.next(true);

    return this.odata.CustomCollectionFunction('AzureGroups').pipe(
      map((res: HttpResponse<{ value: AzureGroup[] }>) => {
        const groups = res.body.value;

        groups.sort((a, b) => naturalSort(a.Name, b.Name));
        groups.unshift({
          Id: '',
          Name: this.allUsersText,
          AutoActivationMode: AutoActivationMode.Disabled
        });

        return groups;
      }),
      finalize(() => this.#loading$.next(false))
    );
  }

  getOrganizationalUnitsGroups(): Observable<OrganizationalUnit[]> {
    this.#loading$.next(true);

    return this.orgUnitsService.fetchGroups().pipe(
      map((res) => res.data),
      map((units) => {
        const notInUnits = units.find((u) => u.Id === GuidEmpty);

        units = units.filter((u) => u.Id !== GuidEmpty).sort((a, b) => naturalSort(a.Path, b.Path));
        units.unshift(
          ...[
            {
              Id: '',
              Path: this.allUsersText,
              AutoActivationMode: AutoActivationMode.Disabled
            } as OrganizationalUnit,
            notInUnits
          ].filter(Boolean)
        );

        return units.map((u) => {
          if (this.authService.isGoogle) {
            u.Path = u?.Path || u?.Name;
          }

          return u;
        });
      }),
      finalize(() => this.#loading$.next(false))
    );
  }

  disableAlternateTwoStep(userId: string, password: string): Observable<any> {
    const options = {
      Password: password
    };

    return this.userService
      .CustomAction(userId, 'DisableAlternateTwoStep', options)
      .pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }

  addAllUsersToBackup(): Observable<any> {
    this.#loading$.next(true);

    return this.odata.CustomCollectionAction('AddAllUsersToBackup', null).pipe(finalize(() => this.#loading$.next(false)));
  }

  setAutoAddUserToBackup(autoAddUserToBackup: boolean): Observable<any> {
    return this.odata.CustomCollectionAction(`SetAutoAddUserToBackup?autoAddUserToBackup=${String(autoAddUserToBackup)}`, null);
  }

  setAutoAddGroupToBackup(autoAddGroupToBackup: boolean): Observable<any> {
    return this.odata.CustomCollectionAction(`SetAutoAddGroupToBackup?autoAddGroupToBackup=${String(autoAddGroupToBackup)}`, null);
  }

  clearCache(): void {
    this.#users$.next([]);
    this.#total$.next(0);
  }

  mapBackupStatisticToGridList(stats: BackupStatistic[], roles: Role[], isGlobalAdmin = true): BackupStatisticGrid[] {
    const getServiceType = (type: string): ServiceType => ServiceTypeByAPIEnum[type].serviceType;

    return stats
      .filter((stat) => {
        const serviceType = getServiceType(stat.Type);

        return (
          (serviceType === ServiceType.SharePoint && !hasUserAccountAdminRole(roles) && isGlobalAdmin) ||
          serviceType !== ServiceType.SharePoint
        );
      })
      .map((stat) => {
        const serviceType = getServiceType(stat.Type);

        return {
          name: getServiceUiInfo(serviceType).displayName,
          size: stat.Size,
          count: serviceType === ServiceType.Contacts ? stat.ItemCount || 0 : stat.ItemCount,
          serviceType: serviceType
        };
      });
  }

  confirmAlternatePassword(token: string, newPassword: string) {
    const options = {
      NewPassword: newPassword,
      Token: token
    } as ConfirmAlternatePassword;

    return this.odata.CustomCollectionAction('ConfirmAlternatePassword', options).pipe(map((res: HttpResponse<{ body: any }>) => res.body));
  }
}
